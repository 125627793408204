/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  --primary: #b00;
  --second: #f8d67d;
  --third: #4f2d23;
  --fourth: #f7f7f7;
  --fifth: #ffffff;
  --a: #BBE1C3;
  --b: #A7CDBD;
  --c: #869D7A;
  --d: #c7b9a9;
  --e: #8B5D33;
  /*font-size: small;*/
}
.mat-body{
  line-height: 1.8;
  font-size: 1.25rem;
  font-family: 'Amethysta', sans-serif;
  width: 70vw;
  max-width: 100%;
}
.mat-h1{
  font-size: 2rem;
  font-family: 'Amethysta', sans-serif;
  max-width: 100%;
}
.mat-h2{
  font-size: 1.7rem;
  font-family: 'Amethysta', sans-serif;
}
.mat-h1-f{
  font-size: 2rem;
  font-family: 'Pacifico', sans-serif;
}
.mat-title{
  font-size: 2.5rem;
  font-family: 'Amethysta', sans-serif;
}

.mat-toolbar{
  font-family: 'Amethysta', sans-serif;
}
.left{
  text-align: left;
}
.white{
  color: white;
}
.small-button{
  width: 15rem;
  margin-left: 0;
  margin-top: 2rem;
}
.button{
  width: 15rem;
  height: 4rem;
  font-size: 1.5rem;
  background-color: var(--third);
  color: white;

}
.center{
  text-align: center;
}
div{
  white-space: pre-wrap;
}
